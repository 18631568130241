img.photobelage {
  width: 540px;
  /* you can use % */
  height: auto; }

 
  .formemailcontainer {
    display: grid;
    height: 100vh;
    grid-template-columns: 10% 1fr 1fr 10%;
    grid-template-rows: 10% 1fr 1fr 10%;
   
  } 
  .outerFormContainer {
    display: grid;
    grid-column: 2/4;
    grid-row: 2/4;
    grid-gap: 200px;
    background-color: #c3dce8;
    border: solid 3px black;
  }
  .innerFormContainer {
    grid-column: 2/2;
    grid-row-start: 1;
    padding-top: 20px;
    position: relative;
    float: right;
    margin-left: auto;
    margin-right: 0;
  }
  
  .navbar .navbar-nav .nav-link:not(.btn) {
    text-transform: uppercase;
    font-size: 1.1142em;
    padding: 0.5rem 0.7rem;
    line-height: 1.625rem;
}

.description-normal-service {
  /* text-align: center; */
  font-weight: 200; }

  .textField {
    display: block;
    background-color: rgb(18, 70, 50);
  }
  #emailIcon {
    width: 100%;
    padding: 100px 50px 75px 100px;
  }
  ul{
    text-decoration: none !important;
    color: red;
  }

  .sent {
    color: rgb(34, 61, 38);
  }


  
  .full-size {
    width: 100vw;
    height: 100vh;
  }

  .section {
    padding: 70px 0;
    position: relative;
    /* background: #644545; */
    background-size: 3px;
    width: 100vw;
    height: 100vh;
}


.footer {
  padding: 24px 0;
  position: fixed;

  }
  .footer.footer-default {
    background-color: #d4cece; }
  .footer nav {
    display: inline-block; }
  .footer ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none; }
    .footer ul li {
      display: inline-block; }
      .footer ul li a {
        color: inherit;
        padding: 0.5rem;
        font-size: 0.8571em;
        text-transform: uppercase;
        text-decoration: none; }
        .footer ul li a:hover {
          text-decoration: none; }
  .footer .copyright {
    font-size: 0.8571em; }
  .footer:after {
    display: table;
    clear: both;
    content: " "; }

    .plan {
      height: 832px;
      width: 787px;
    }

    .logonancy {
      border-radius: 8px;
      width: 300px;
      height: 175px;
    }


    .cartes {
      border-radius: 8px;
      width: 215px;
      height: 80px;
      padding: 5px 0;
    }

    .interac {
      border-radius: 8px;
      width: 80px;
      height: 80px;
      padding: 5px 0;
    }

    .roulotte {
      border-radius: 8px;
      width: 240px;
      height: 180px;
      padding: 5px 0;
    }

