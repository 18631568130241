
/* armata-regular - latin */
@font-face {
    font-family: 'Armata';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/armata-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../assets/fonts/armata-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../assets/fonts/armata-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../assets/fonts/armata-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../assets/fonts/armata-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../assets/fonts/armata-v12-latin-regular.svg#Armata') format('svg'); /* Legacy iOS */
  }


/* ewert-regular - latin */
@font-face {
    font-family: 'Ewert';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/ewert-v8-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../assets/fonts/ewert-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../assets/fonts/ewert-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../assets/fonts/ewert-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../assets/fonts/ewert-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../assets/fonts/ewert-v8-latin-regular.svg#Ewert') format('svg'); /* Legacy iOS */
  }

/* armata-regular - latin */
@font-face {
    font-family: 'Lobster';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/Lobster-Regular.ttf'); /* IE9 Compat Modes */
    src: local(''),
         
         url('../assets/fonts/Lobster-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  }  

.armataBig {
    font-family: 'Armata', cursive;
    font-size: 42px;
    color:green
}

.armataMedium {
    font-family: 'Armata', cursive;
    font-size: 15px;
    color:green
}

.armataMediumBlack {
    font-family: 'Armata', cursive;
    font-size: 15px;
    color:rgb(7, 7, 7)
}



.LobsterLargeRed {
    font-family: 'Lobster', cursive;
    font-size: 40px;
    color:rgb(128, 74, 74)
}


.armataSmall {
    font-family: 'Armata', cursive;
    font-size: 18px;
    color:rgb(128, 74, 74)
}

.armataSubtitleBlack {
    font-family: 'Armata', cursive;
    font-size: 18px;
    color:rgb(10, 10, 10)
}

.bangersBig {
    font-family: 'Armata', cursive;
    font-size: 66px;
    color:white
}


.img-icon {
    width: 20px;
    height: auto;
}
